import React, { useState } from "react";
import { ReactComponent as CloseMenu } from "./assets/x.svg";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
// import { ReactComponent as Logo } from "../assets/navlogo.svg";
import "./Header.css";
import navLogo from "../../Images/logos/navlogo.jpg";
import { useNavigate, NavLink } from "react-router-dom";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="logo-nav">
        <div className="mobile-menu" onClick={handleClick}>
          {click ? (
            <CloseMenu className="menu-icon" />
          ) : (
            <MenuIcon className="menu-icon" />
          )}
        </div>
        <ul className={click ? "nav-options active" : "nav-options"}>
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/")}>Home</a>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/stock")}>Stock</a>
          </li>
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/sellYourCar")}>Sell</a>
          </li>{" "}
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/services")}>Services</a>
          </li>{" "}
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/financing")}>Financing</a>
          </li>{" "}
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/insurance")}>Insurane</a>
          </li>{" "}
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/aboutUs")}>About us</a>
          </li>{" "}
          {/* <li className="option" >
            <a href="https://theautocops.com/blog/">Blog</a>
          </li> */}
          <li className="option" onClick={closeMobileMenu}>
            <a onClick={() => navigate("/contactUs")}>Contact us</a>
          </li>
        </ul>
      </div>

      <div className="logo-container">
        <a onClick={() => navigate("/")}>
          <img className="logo" src={navLogo} alt="logo" />{" "}
        </a>
      </div>
    </div>
  );
};

export default Header;
